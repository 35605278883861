import { Component } from "react";
import { 
    BannerWrapper, 
    TopPanel, 
    MiddlePanel,
    BottomPanel,
    SearchBarWrapper, 
    HomeIconContainer,
    UserInterfaceElements 
} from "./UserPortalBannerElements";
import SearchBar from "./SearchBar";
import UserMenuParent from "../UserManuParent";
import { Link } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';

class UserPortalBanner extends Component {
    render() {
        const username = this.props.username;
        const signOut = this.props.signOut;
        return (
            <BannerWrapper>
                <TopPanel>
                    <SearchBarWrapper>
                        <SearchBar />
                    </SearchBarWrapper>
                    <UserInterfaceElements>
                        <HomeIconContainer aria-label="return home" component={Link} to="/">
                            <HomeIcon sx={{ color: "white" }} />
                        </HomeIconContainer>
                        <UserMenuParent signOut={signOut}/>
                    </UserInterfaceElements>
                </TopPanel>
                <MiddlePanel>
                    <h1 style={{ color: 'white' }}>
                        {username}'s Portal
                    </h1>
                </MiddlePanel>
                <BottomPanel>
                    <p style={{ fontSize: '16px', fontFamily: 'Comic Sans', textAlign: 'center', color: 'white' }}>
                        This is your portal for managing your projects with me. You can start a new project, ask for a quote, and manage the work schedule with existing projects
                    </p>
                </BottomPanel>
            </BannerWrapper>
        )
    }
}

export default UserPortalBanner;