import { 
    Box,
    Paper,
    IconButton
} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import SmsIcon from '@mui/icons-material/Sms';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SmartToyIcon from '@mui/icons-material/SmartToy';

import React from "react";

const BottomToolBar = ({ setActiveTab }) => {
    return (
        <Box sx={{ py: '1rem' }}>
            <Paper elevation={2} sx={{ height: '10vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <IconButton onClick={() => setActiveTab("functionList")}>
                    <HomeIcon fontSize="large" />
                </IconButton>
                <IconButton onClick={() => setActiveTab("basicTabs")}>
                    <WorkIcon fontSize="large" />
                </IconButton>
                <IconButton onClick={() => setActiveTab("messages")}>
                    <SmsIcon fontSize="large" />
                </IconButton>
                <IconButton onClick={() => setActiveTab("notifications")}>
                    <NotificationsIcon fontSize="large" />
                </IconButton>
                <IconButton onClick={() => setActiveTab("chatbot")}>
                    <SmartToyIcon fontSize="large" />
                </IconButton>
            </Paper>
        </Box>
    )
}

export default BottomToolBar;
