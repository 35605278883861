import React, { useState, forwardRef } from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    IconButton,
    Dialog,
    Slide,
    AppBar,
    Toolbar
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import { updateBlog as updateBlogMutation } from "../../../../graphql/customMutations";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateBlog({ blog, client, tokens, fetchBlogs }) {
    const [open, setOpen] = useState(false);
    const [blogName, setBlogName] = useState(blog.name);
    const [blogSummary, setBlogSummary] = useState(blog.summary);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function updateBlog() {
        const data = {
            id: blog.id,
            name: blogName,
            summary: blogSummary
        };
        await client.graphql({
            query: updateBlogMutation,
            variables: { input: data },
            authMode: "userPool",
            authToken: tokens.accessToken
        });
        fetchBlogs();
    }

    return (
        <Box sx={{ width: '90%' }}>
            <IconButton onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant='h6' color={'white'} component="div">
                            Update Blog
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => {
                            updateBlog();
                            handleClose();
                        }}>
                            save & update
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100%",
                        p: 2,
                        gap: 2
                    }}
                >
                    <TextField
                        id="name"
                        name="name"
                        value={blogName}
                        placeholder="Blog Name"
                        label="Blog Name"
                        required
                        fullWidth
                        onChange={(e) => setBlogName(e.target.value)}
                    />
                    <TextField
                        id="summary"
                        name="summary"
                        value={blogSummary}
                        placeholder="Blog Summary"
                        label="Blog Summary"
                        multiline
                        required
                        fullWidth
                        rows={4}
                        onChange={(e) => setBlogSummary(e.target.value)}
                    />
                </Box>
            </Dialog>
        </Box>
    );
}
