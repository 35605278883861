import React from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Divider
} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function FunctionList() {
    return (
        <List
            sx={{ width: '100%' }}
            aria-label="contacts"
        >
            <ListItem>
                <ListItemButton>
            <ListItemText primary="Dashboard" />
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                    <ArrowForwardIosIcon />
                </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <Divider variant="middle" component="li" />
            <ListItem>
                <ListItemButton>
                <ListItemText primary="Submit a Support Ticket" />
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                    <ArrowForwardIosIcon />
                </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <Divider variant="middle" component="li" />
            <ListItem>
                <ListItemButton>
            <ListItemText primary="Billing and Payments" />
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                    <ArrowForwardIosIcon />
                </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <Divider variant="middle" component="li" />
            <ListItem>
                <ListItemButton>
                <ListItemText primary="File Sharing and Storage" />
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                    <ArrowForwardIosIcon />
                </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <Divider variant="middle" component="li" />
            <ListItem>
                <ListItemButton>
            <ListItemText primary="Request Services or Schedule Appointments" />
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                    <ArrowForwardIosIcon />
                </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <Divider variant="middle" component="li" />
            <ListItem>
                <ListItemButton>
                <ListItemText primary="Knowledge Base" />
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                    <ArrowForwardIosIcon />
                </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <Divider variant="middle" component="li" />
        </List>
    )
}