import React from 'react';
import DrawerComponent from '../../components/Drawer';
import {
	Box,
	Typography
} from "@mui/material";
import contents from '../../data/aboutData';
import { IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

const AboutMobileView = () => {
	return (
		<Box>
			<Box>
				<DrawerComponent />
			</Box>
			<Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '80%',
                    margin: 'auto',
                    padding: 2,
                }}
            >
				{contents.map((content, index) => (
					<Box
						key={index}
					>
						<Typography
							variant='h4'
							sx={{
								marginBlock: 2,
								marginBottom: 4,
							}}
						>
							{content.title}
						</Typography>
						{index === 0 ? (
							<Box
								sx={{
									marginBottom: 2,
								}}
							>
								<IconButton href="https://www.linkedin.com/in/tsz-chung-chan-59964616b" target="_blank">
									<LinkedInIcon className="muiIcon"/>
								</IconButton>
								<IconButton href="https://github.com/tszchung019" target="_blank">
									<GitHubIcon className="muiIcon"/>
								</IconButton>
							</Box>
						) : null}
						{content.paragraphs.map((paragraph, paraIndex) => (
							<Typography
								variant='body1'
								key={paraIndex}
								sx={{
									marginBottom: 2,
								}}
							>
								{paragraph}
							</Typography>
						))}
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default AboutMobileView;
