import React from 'react';
import '../css/styles.css';
import {
	useTheme,
	useMediaQuery,
	Box,
} from "@mui/material";
import AboutMobileView from '../layouts/mobile/about';
import AboutDesktopView from '../layouts/desktop/about';

const About = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<Box>
			{isMobile ? (
				<AboutMobileView />
			) : (
				<AboutDesktopView />
			)}
		</Box>
	);
};

export default About;
