import React from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import Navbar from "../../components/Navbar";
import MyInfo from "../../components/Welcome/MyInfo";
import employment from "../../data/employmentData";

export default function ExperienceDesktopView() {
    return (
        <Box>
            <Box>
                <Navbar />
            </Box>
            <Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
                <MyInfo />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '70%',
                        margin: 'auto',
                        padding: 2,
                    }}
                >
                    <Typography
                        variant='h4'
                        sx={{
                            marginBlock: 2,
                            marginBottom: 4,
                        }}
                    >
                        My Experience
                    </Typography>
                    {employment.map((job, index) => (
                        <Paper
                            key={index}
                            sx={{
                                padding: 2,
                                marginBottom: 4,
                                backgroundColor: 'rgb(255, 255, 255, 0.9)'
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        {job.title}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        <Typography variant="subtitle1" color="textSecondary" sx={{ marginRight: 2 }}>
                                            {job.company}
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {job.serviceStart} - {job.serviceEnd}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        {job.summary}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Accomplishments:
                                    </Typography>
                                    <ul>
                                        {job.accomplishments.map((accomplishment, accIndex) => (
                                            <li key={accIndex}>
                                                <Typography variant="body2">
                                                    {accomplishment}
                                                </Typography>
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Technologies:
                                    </Typography>
                                    <Grid
                                        container
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        {job.technologies.map((technology, tecIndex) => (
                                            <Grid item key={tecIndex}>
                                                <Paper sx={{ padding: '4px', borderRadius: '1rem', backgroundColor: 'rgb(220, 250, 230, 0.7)' }}>
                                                    <Typography color={'#3c9bd6'} fontSize={'x-small'}>{technology}</Typography>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}
