const employment = [
    {
        title: "Full Stack Developer",
        company: "My Claim Group",
        serviceStart: "August 2023",
        serviceEnd: "March 2024",
        summary: "Engaged in the development of various applications enhancing data analysis efficiency and streamlining critical business processes within multiple CRM systems.",
        accomplishments: [
            "Initiated and executed campaigns resulting in increased client acquisition",
            "Continuously enhance and refine existing CRMs based on user feedback, ensuring optimal performance and user satisfaction"
        ],
        technologies: [
            "PHP",
            "Laravel",
            "TypeScript",
            "ReactJS",
            "NodeJS",
            "TailwindCSS"
        ]
    },
    {
        title: "Software Developer",
        company: "Self Employed",
        serviceStart: "November 2022",
        serviceEnd: "July 2023",
        summary: "Work as a freelancer to integrate new applications to client’s websites.",
        accomplishments: [
            "Installed and configured a chatbot to answer frequently asked questions",
            "Installed and configured a content generation application that saved 50% of time creating blog posts"
        ],
        technologies: [
            "ReactJS",
            "NodeJS",
            "Python",
            "PHP",
            "AWS"
        ]
    },
    {
        title: "Solution Developer",
        company: "Automated Systems (HK) Limited",
        serviceStart: "September 2019",
        serviceEnd: "September 2022",
        summary: "Spearheaded the creation of a contextual chatbot with JavaScript and Python frameworks, demonstrating diverse expertise, collaboration with the project manager, mentorship of a junior developer, efficient management of the Git repository, and leadership within an agile framework to ensure project success.",
        accomplishments: [
            "Led development of a contextual chatbot using an open-source framework, with revenue projections highlighting its potential success",
            "Demonstrated diverse development expertise and collaborative approach",
            "Defined technical specifications in close collaboration with the project manager",
            "Provided mentorship to a junior developer, fostering their growth and contributing to project success"
        ],
        technologies: [
            "Java",
            "JavaScript",
            "Python",
            "NodeJS",
            "Docker",
            "Kubernetes"
        ]
    },
    {
        title: "Data Engineer",
        company: "Innodee Limited",
        serviceStart: "June 2018",
        serviceEnd: "September 2018",
        summary: "Took part in a startup company in their data team; responsible for developing an ETL pipeline to keep track of the US property market",
        accomplishments: [
            "Implemented a web crawler to perform data extraction from property websites in the US, improving the efficiency by 42%",
            "Implemented a data cleansing program that processed the raw data extracted from APIs and crawlers, reducing 60% of time working with data",
            "Defined technical specifications in close collaboration with the project manager",
            "Created a dataset on GCP BigQuery to store the data after cleansing, which saved $40,000 in the annual budget"
        ],
        technologies: [
            "Python",
            "Selenium",
            "GCP"
        ]
    }
];

export default employment;
