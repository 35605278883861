const skills = [
    {
        group: "Technical Skills",
        categories: [
            {
                catName: "Programming Languages",
                items: [
                    "JavaScript",
                    "TypeScript",
                    "Python",
                    "Java",
                    "PHP",
                    "C++"
                ]
            },
            {
                catName: "Front-end Development",
                items: [
                    "HTML",
                    "CSS",
                    "React",
                    "jQuery"
                ]
            },
            {
                catName: "Back-end Development",
                items: [
                    "Node.js",
                    "Flask",
                    "Laravel"
                ]
            },
            {
                catName: "Databases",
                items: [
                    "SQL",
                    "PostgreSQL",
                    "MySQL",
                    "MongoDB",
                    "SQLite",
                    "Redis"
                ]
            },
            {
                catName: "DevOps and CI/CD",
                items: [
                    "Docker",
                    "Kubernetes"
                ]
            },
            {
                catName: "Version Control",
                items: [
                    "Git",
                    "GitHub",
                    "GitLab",
                    "Bitbucket"
                ]
            },
            {
                catName: "Cloud Services",
                items: [
                    "AWS",
                    "Google Cloud Platform",
                    "Heroku"
                ]
            },
            {
                catName: "Testing and QA",
                items: [
                    "Selenium",
                    "Postman"
                ]
            },
            {
                catName: "APIs and Web Services",
                items: [
                    "REST",
                    "GraphQL"
                ]
            },
            {
                catName: "Data Analysis and Visualization",
                items: [
                    "Pandas",
                    "NumPy",
                    "Matplotlib",
                    "Tableau"
                ]
            },
            {
                catName: "Machine Learning and Artificial Intelligence",
                items: [
                    "PyTorch",
                    "Scikit-Learn",
                    "TensorFlow",
                    "Keras",
                    "XGBoost",
                    "Hugging Face"
                ]
            }
        ]
    },
    {
        group: "Methodologies and Frameworks",
        categories: [
            {
                catName: "Development Methodologies",
                items: [
                    "Agile",
                    "Scrum",
                    "Waterfall",
                    "TDD",
                    "BDD"
                ]
            },
            {
                catName: "Design Patterns",
                items: [
                    "MVC",
                    "Singleton",
                    "Factory",
                    "Decorator"
                ]
            }
        ]
    },
    {
        group: "Tools and Software",
        categories: [
            {
                catName: "IDEs and Text Editors",
                items: [
                    "Visual Studio Code",
                    "PyCharm",
                    "Eclipse"
                ]
            },
            {
                catName: "Build Tools",
                items: [
                    "Maven",
                    "Webpack"
                ]
            },
            {
                catName: "Project Management Tools",
                items: [
                    "Jira"
                ]
            },
            {
                catName: "Collaboration Tools",
                items: [
                    "Slack",
                    "Microsoft Teams",
                    "Zoom",
                    "Google Meet"
                ]
            }
        ]
    }
];

export default skills;