import React from 'react';
import '../css/styles.css';
import ProjectsDesktopView from '../layouts/desktop/projects.js';
import ProjectsMobileView from '../layouts/mobile/projects.js';
import {
	useTheme,
	useMediaQuery,
    Box,
} from "@mui/material";

const Project = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box>
            {isMobile ? (
                <ProjectsMobileView />
            ) : (
                <ProjectsDesktopView />
            )}
        </Box>
    );
};

export default Project;
