import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import employment from '../data/employmentData';
import skills from '../data/skillData';
import '../App.css';
import { Box, Button, Divider, Typography } from '@mui/material';
import { NavBtnLink } from './Navbar/NavbarElements';

import {
    LinkedinShareButton,
    WhatsappShareButton,
    EmailShareButton,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';

const CV = () => {
    const shareUrl = window.location.href;
    const title = "Resume--Tsz Chung Chan";

    const generatePDF = async () => {
        const content = document.getElementById('content');

        const canvas = await html2canvas(content, { scale: 2 }); // Scale the canvas for better resolution
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF('p', 'pt', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;

        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
            heightLeft -= pdfHeight;
        }

        pdf.save('document.pdf');
    };

    return (
        <div>
            <div id="content" className="content">
                <div className="personal-info">
                    <h2>Tsz Chung Chan</h2>
                    <p>07442608701</p>
                    <p>arthurchan705119@protonmail.com</p>
                    {skills.map((skillGroup, index) => (
                        <div key={index} className="skill-group">
                            <h3>{skillGroup.group}</h3>
                            {skillGroup.categories.map((category, catIndex) => (
                                <div key={catIndex} className="skill-category">
                                    <h4>{category.catName}</h4>
                                    <ul>
                                        {category.items.map((item, itemIndex) => (
                                            <li key={itemIndex}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="main-content">
                    <div className="professional-summary">
                        <h1>Professional Summary</h1>
                        <p>Experienced Full Stack Developer proficient in Java, JavaScript, and Python for CMS implementations in insurance and government sectors, and PHP for CRM systems. Successfully led a chatbot project generating $200,000 in revenue. Recognized for pioneering AI assistant integration in bespoke CMS projects and staying updated with the latest tech trends. Currently seeking roles in data analytics and software engineering.</p>
                    </div>
                    {employment.map((job, index) => (
                        <div key={index} className="job">
                            <h2>{job.title}</h2>
                            <h3>{job.company}</h3>
                            <p>{job.serviceStart} - {job.serviceEnd}</p>
                            <p>{job.summary}</p>
                            <h4>Accomplishments:</h4>
                            <div className="accomplishments">
                                {job.accomplishments.map((item, i) => (
                                    <div key={i} className="accomplishment">{item}</div>
                                ))}
                            </div>
                            <h4>Technologies:</h4>
                            <div className="technologies">
                                {job.technologies.map((tech, i) => (
                                    <span key={i} className="technology">{tech}</span>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div className="education">
                        <h1>Education</h1>
                        <h3>BEng in Computer Engineering</h3>
                        <h4>The Hong Kong University of Science and Technology</h4>
                        <p>September 2016 - May 2019</p>
                    </div>
                </div>
            </div>
            <Box
                sx={{
                    display: 'flex',
                    margin: 2,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Button disableElevation variant='contained' onClick={generatePDF}>Generate PDF</Button>
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    margin: 2,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    variant="h4"
                >
                    Share this resume
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    margin: 2,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box sx={{ mx: 1 }}>
                    <LinkedinShareButton url={shareUrl} title={title}>
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </Box>
                <Box sx={{ mx: 1 }}>
                    <WhatsappShareButton url={shareUrl} title={title}>
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </Box>
                <Box sx={{ mx: 1 }}>
                    <EmailShareButton url={shareUrl} title={title}>
                        <EmailIcon size={32} round />
                    </EmailShareButton>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    margin: 2,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <NavBtnLink to={'/'}>Return Home</NavBtnLink>
            </Box>
        </div>
    );
};

export default CV;
