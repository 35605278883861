import React from 'react';
import '../../css/styles.css';
import Navbar from '../../components/Navbar';
import { Box, Typography } from '@mui/material';
import MyInfo from '../../components/Welcome/MyInfo';

const ProjectsDesktopView = () => {
    return (
        <Box>
            <Box>
                <Navbar />
            </Box>
            <Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
                <MyInfo />
                <Box
                    sx={{
                        display: 'relative',
                        width: '70%',
                        marginX: 'auto',
                        padding: 2,
                    }}
                >
                    <Typography
                        variant='h4'
                        sx={{
                            marginBlock: 2,
                            marginBottom: 4,
                        }}
                    >
                        My Projects
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            marginBottom: 2,
                        }}
                    >
                        I mainly worked on <b>private</b> projects, if you want a demo, please contact me at 
                        <a href="mailto:arthurchan705119@protonmail.com" style={{ textDecoration: 'none', color: '#3c9bd6' }}> arthurchan705119@protonmail.com </a> or visit my <a href="https://github.com/tszchung019" style={{ textDecoration: 'none', color: '#3c9bd6' }}> GitHub</a>.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default ProjectsDesktopView;
