import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports.js';
// import amplifyconfig from './amplifyconfiguration.json';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';

Amplify.configure(awsmobile);
// Amplify.configure(amplifyconfig);
Amplify.configure({
  ...Amplify.getConfig(),
  Interactions: {
    LexV2: {
      'HelpDesk': {
        aliasId: 'TSTALIASID',
        botId: 'EZ163BJIRS',
        localeId: 'en_GB',
        region: 'us-east-1'
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
