import React from 'react';
import DrawerComponent from '../../components/Drawer';
import {
	Typography,
	Box
} from "@mui/material";
import contents from '../../data/homeData';

const HomeMobileView = () => {
	return (
		<Box>
			<Box>
                <DrawerComponent />
			</Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '80%',
                    margin: 'auto',
                    padding: 2,
                }}
            >
                {contents.map((content, index) => (
                    <Box
                        key={index}
                    >
                        <Typography
                            variant='h4'
                            sx={{
                                marginBlock: 2,
                                marginBottom: 4,
                            }}
                        >
                            {content.title}
                        </Typography>
                        {content.paragraphs.map((paragraph, paraIndex) => (
                            <Typography
                                variant='body1'
                                key={paraIndex}
                                sx={{
                                    marginBottom: 2,
                                }}
                            >
                                {paragraph}
                            </Typography>
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>
	);
};

export default HomeMobileView;
