import React, { useState, useEffect } from "react";
import "../../css/styles.css";
import DrawerComponent from "../../components/Drawer";
import {
  Paper,
  Box,
  Typography,
  styled
} from "@mui/material";
import { generateClient } from 'aws-amplify/api';
import {
  listBlogs as listBlogsQuery,
} from "../../graphql/queries";
import { useNavigate } from "react-router-dom";

// Styled version of the Paper component
const CustomPaper = styled(Paper)`
  cursor: pointer;
`;

const BlogsDesktopView = () => {
  const [blogs, setBlogs] = useState([]);
  const client = generateClient();
  const navigate = useNavigate();

  const s3bucket = process.env.REACT_APP_S3_BUCKET;

  useEffect(() => {
    fetchBlogs();
  }, []);

  async function fetchBlogs() {
    const apiData = await client.graphql({ query: listBlogsQuery });
    const blogsFromAPI = apiData.data.listBlogs.items;
    setBlogs(blogsFromAPI);
  }

  async function navigateToBlog(blog) {
    navigate('./' + blog.id);
  }

  return (
    <Box>
      <DrawerComponent />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '80%',
          margin: 'auto',
          padding: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
						marginBlock: 2,
						marginBottom: 4,
					}}
        >
          Arthur's Blogs
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            gap: 2
          }}
        >
          {blogs.map((blog) => (
            <Box
              onClick={() => navigateToBlog(blog)}
              sx={{
                cursor: 'pointer',
                width: 300, // Fixed width
                height: 400, // Fixed height
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 2,
                overflow: 'hidden',
                position: 'relative', // Required for absolute positioning inside
                marginBottom: 2,
                // Responsive adjustments
                '@media (max-width: 600px)': {
                  width: 'calc(100% - 16px)', // Full width on small screens
                },
              }}
              key={blog.id || blog.name}
            >
              <CustomPaper
                elevation={6}
                sx={{
                  height: '40%', // Flex height for image
                  backgroundImage: `url(${s3bucket}${blog.imgPath})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '16px 16px 0 0',
                }}
              />
              <CustomPaper
                elevation={6}
                sx={{
                  height: '60%', // Flex height for text
                  padding: 2,
                  borderRadius: '0 0 16px 16px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box>
                <Typography variant="h5" sx={{ textAlign: 'center', py: '10px' }}>
                  {blog.name}
                </Typography>
                </Box>
                <Box>
                <Typography sx={{ fontSize: 'small', textAlign: 'center' }}>
                  {blog.summary}
                </Typography>
                </Box>
              </CustomPaper>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default BlogsDesktopView;
