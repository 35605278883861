// theme.js
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    palette: {
        primary: {
        main: '#1976d2', // Customize primary color
        },
        secondary: {
        main: '#dc004e', // Customize secondary color
        },
    },
    typography: {
        h4: {
            fontWeight: 700,
            font: 'Arial',
            color: '#031d4d',
        },
        h6: {
            fontWeight: 400,
            font: 'Arial',
            color: '#031d4d',
        },
        subtitle1: {
            font: 'Arial',
            color: '#031d4d',
        },
        body1: {
            fontSize: '1rem',
            font: 'Arial',
            color: '#031d4d',
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
