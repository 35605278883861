import React from 'react';
import '../../css/styles.css';
import DrawerComponent from '../../components/Drawer';
import { Box, Typography } from '@mui/material';

const ProjectsMobileView = () => {
    return (
        <Box>
            <Box>
                <DrawerComponent />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '80%',
                    margin: 'auto',
                    padding: 2,
                }}
            >
                <Typography
                    variant='h4'
                    sx={{
						marginBlock: 2,
						marginBottom: 4,
					}}
                >
                    My Projects
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
						marginBottom: 2,
					}}
                >
                    I mainly worked on <b>private</b> projects, if you want a demo, please contact me at 
                    <a href="mailto:arthurchan705119@protonmail.com" style={{ textDecoration: 'none', color: '#3c9bd6' }}> arthurchan705119@protonmail.com </a> or visit my <a href="https://github.com/tszchung019" style={{ textDecoration: 'none', color: '#3c9bd6' }}> GitHub</a>.
                </Typography>
            </Box>
        </Box>
    );
};

export default ProjectsMobileView;
