import React, { useState } from "react";
import UserMenu from "./UserMenu";
import UserProfile from "./UserProfile";

const UserMenuParent = ({ username, signOut }) => {
    const [profileOpen, setProfileOpen] = useState(false);

    const handleClickOpen = () => {
        setProfileOpen(true);
    };

    const handleClickClose = () => {
        setProfileOpen(false);
    };

    return (
        <div>
            <UserMenu 
                username={username} 
                signOut={signOut} 
                handleClickOpen={handleClickOpen} 
            />
            <UserProfile 
                open={profileOpen} 
                handleClickClose={handleClickClose} 
            />
        </div>
    );
};

export default UserMenuParent;
