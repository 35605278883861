import React, { useState, useEffect, Component } from "react";
import { withAuthenticator, Text } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import BasicTabs from '../BasicTabs.js';
import { Box, Button, Paper, Typography } from '@mui/material';
import ManageBlogPanel from "./ManageBlogPanel/index.js";
class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group:'',
            username:''
        };
    }
    async componentDidMount() {
        const { tokens } = await fetchAuthSession();
        const { username } = await getCurrentUser();
        const groupname = tokens.accessToken.payload["cognito:groups"] ? tokens.accessToken.payload["cognito:groups"][0] : "";
        this.setState({ group:groupname, username: username });
    }
    render() {
        const { group, username }= this.state
        if (/^Admin$/i.test(group))
            return (
                <Box>
                    <Button variant="text" sx={{position: 'absolute', top: '5px', right: '10px'}} onClick={signOut}>Sign out</Button>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '80%',
                            margin: 'auto',
                            padding: 2,
                          }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2
                            }}
                        >
                            <Typography
                                variant="h4"
                            >
                                Welcome, administrator {username}!
                            </Typography>
                            <ManageBlogPanel />
                        </Box>
                        <Box
                            sx={{
                                p: 2
                            }}
                        >
                            <Paper>
                                <BasicTabs
                                    user={username}
                                />
                            </Paper>
                        </Box>
                    </Box>
                </Box>
            )
        else
            return (
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '80%',
                            margin: 'auto',
                            padding: 2,
                          }}
                    >
                        <Typography>
                            Loading...
                        </Typography>
                    </Box>
                </Box>
            )
    }
}

export default withAuthenticator(Admin, { hideSignUp: true });