import React from 'react';
import Navbar from '../../components/Navbar';
import {
	Typography,
	Box
} from "@mui/material";
import MyInfo from '../../components/Welcome/MyInfo';
import contents from '../../data/homeData';

const HomeDesktopView = () => {
	return (
		<Box>
			<Box>
                <Navbar />
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<MyInfo />
				<Box
					sx={{
                        display: 'relative',
                        width: '70%',
                        marginX: 'auto',
                        padding: 2,
                    }}
				>
					{contents.map((content, index) => (
                        <Box
                            key={index}
                        >
                            <Typography
                                variant='h4'
                                sx={{
                                    marginBlock: 2,
                                    marginBottom: 4,
                                }}
                            >
                                {content.title}
                            </Typography>
                            {content.paragraphs.map((paragraph, paraIndex) => (
                                <Typography
                                    variant='body1'
                                    key={paraIndex}
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                >
                                    {paragraph}
                                </Typography>
                            ))}
                        </Box>
                    ))}
				</Box>
			</Box>
		</Box>
	);
};

export default HomeDesktopView;
