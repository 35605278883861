import React, { useState, useRef, useEffect } from "react";
import BotInteraction from "../BotInteraction";
import { Box, IconButton, Collapse } from "@mui/material";
import SmartToyIcon from '@mui/icons-material/SmartToy';

export default function BotWidget() {
    const [open, setOpen] = useState(false);
    const containerRef = useRef(null);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Box
            ref={containerRef}
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                display: 'flex',
                flexDirection: 'column-reverse',
                alignItems: 'flex-end',
            }}
        >
            <Box
                sx={{
                    width: 50,
                    height: 50,
                    borderRadius: '50%',
                    backgroundColor: 'blue',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 1,
                }}
            >
                <IconButton onClick={handleClick}>
                    <SmartToyIcon sx={{ color: 'white' }} />
                </IconButton>
            </Box>
            <Collapse in={open} sx={{ transformOrigin: 'bottom', px: 1, py: 1 }}>
                <Box
                    sx={{
                        height: '100vh',
                        width: '100vw',
                        maxHeight: '400px',
                        maxWidth: '300px',
                        borderRadius: '8px',
                        boxShadow: 3,
                        backgroundColor: 'white',
                    }}
                >
                    <BotInteraction />
                </Box>
            </Collapse>
        </Box>
    );
}