import React from 'react';
import '../css/styles.css';
import SkillsDesktopView from '../layouts/desktop/skills.js';
import SkillsMobileView from '../layouts/mobile/skills.js';
import {
	useTheme,
	useMediaQuery,
	Box
} from "@mui/material";

const Skills = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Box>
			<Box>
				{isMobile ? (
					<SkillsMobileView />
				) : (
					<SkillsDesktopView />
				)}
			</Box>
		</Box>
	);
};

export default Skills;
