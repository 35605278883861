import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CurrentProjects from './CurrentProjects';
import Timeline from './Timeline';
import NewProject from './NewProject';
import { fetchAuthSession } from "aws-amplify/auth";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({user}) {
  const [value, setValue] = React.useState(0);
  const [tokens, setTokens] = useState(null);

  const fetchToken = async () => {
    const tokens = await fetchAuthSession();
    setTokens(tokens);
  };

  useEffect(() => {
    fetchToken();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="basic tabs example">
          <Tab label="New Project" {...a11yProps(0)} />
          <Tab label="Current Projects" {...a11yProps(1)} />
          <Tab label="Timelines" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <NewProject 
          tokens={tokens}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CurrentProjects
          user={user}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Timeline />
      </TabPanel>
    </Box>
  );
}