import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    TextField,
    Typography,
    Button
} from '@mui/material';
import { generateClient } from 'aws-amplify/api';

import {
    createBlog as createBlogMutation,
    deleteBlog as deleteBlogMutation
} from "../../../graphql/customMutations";

export default function CreateBlog({ tokens }) {
    const client = generateClient();

    async function createBlog(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
            name: form.get("name"),
            summary: form.get("description")
        };
        await client.graphql({
            query: createBlogMutation,
            variables: { input: data },
            authMode: "userPool",
            authToken: tokens.accessToken
        });
        event.target.reset();
    }

    return (
        <Box sx={{ width: '90%' }}>
            <Box
                component="form"
                onSubmit={createBlog}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2
                }}
            >
                <TextField
                    name="name"
                    placeholder="Blog Name"
                    label="Blog Name"
                    required
                    fullWidth
                />
                <TextField
                    name="description"
                    placeholder="Blog Summary"
                    label="Blog Summary"
                    multiline
                    required
                    fullWidth
                    rows={4}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Create Blog
                </Button>
            </Box>
        </Box>
    );
}