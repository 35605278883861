import React, { Component } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import '../css/styles.css';
import UserPortal from '../components/UserPortal/index.js';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username:''
    };
  }
  async componentDidMount() {
    const { username } = await getCurrentUser();
    this.setState({ username: username });
  }
  render() {
    const { username }= this.state
    return (
      <UserPortal username={username} signOut={signOut} />
    )
  }
}

export default withAuthenticator(SignUp);
