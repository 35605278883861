import React, { useState, useEffect } from "react";
import { generateClient } from 'aws-amplify/api';
import { 
    getBlog as getBlogQuery,
} from "../../graphql/customQueries";
import { useParams, useNavigate } from "react-router-dom";
import DrawerComponent from '../Drawer';
import Navbar from '../Navbar';
import {
	useTheme,
	useMediaQuery,
    Typography
} from "@mui/material";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from "@mui/material/Box";

const columns = [
    { id: 'title', label: 'Title', minWidth: '45vw' },
    {
      id: 'createdAt',
      label: 'Created at',
      minWidth: 150,
      format: (value) => value.toLocaleString('en-US'),
    },
  ];

const BlogPost = () => {
    const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const {id} = useParams();
    const [posts, setPosts] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const client = generateClient();
    const navigate = useNavigate()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getPostsfromBlog({id});
      }, []);

    async function getPostsfromBlog({ id }) {
        const apiData = await client.graphql({
          query: getBlogQuery,
          variables: { id: id },
        });
        const postsFromAPI = apiData.data.getBlog.posts.items;
        const sortedBlogPosts = postsFromAPI.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        sortedBlogPosts.map((post) => {
            post.createdAt = post.createdAt.split("T")[0];
            post.updatedAt = post.updatedAt.split("T")[0];
        });
        setPosts(sortedBlogPosts);
    }

    async function navigateToPost(post) {
        navigate('../posts/' + post.id);
    }

    return (
        <Box>
            {isMobile ? (
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '90%',
                            margin: 'auto',
                            padding: 2,
                        }}
                    >
                        <Paper elevation={0} sx={{ backgroundColor: 'rgba(205, 255, 216, 0.7)' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <DrawerComponent />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                position: 'absolute',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                            }}
                                        >
                                            Posts
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                        <Paper sx={{ 'margin-top': '2%', width: '100%', overflow: 'hidden' }}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                <TableBody>
                                {
                                    posts.length > 0? posts.map((post) => {
                                        return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={post.id} onClick={() => navigateToPost(post)}>
                                            <TableCell>
                                                <Box sx={{ flexDirection: 'column' }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ fontSize: 'small' }}
                                                    >
                                                        {post.title}
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{ fontSize: 'x-small', textAlign: 'right' }}
                                                    >
                                                    {post.createdAt}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        );
                                    }) : (<TableRow><TableCell colSpan={3} align="center">There is no content</TableCell></TableRow>)
                                }
                                </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={posts.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                sx={{
                                    '& .MuiTablePagination-toolbar': {
                                        fontSize: 'small',
                                    },
                                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select, & .MuiTablePagination-menuItem': {
                                        fontSize: 'x-small',
                                    }
                                }}
                            />
                        </Paper>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <Navbar />
                    <Box
                        sx={{
                            display: 'relative',
                            width: '70%',
                            marginX: 'auto',
                            padding: 2,
                        }}
                    >
                        <Typography
                            variant="h4"
                        >
                            Posts
                        </Typography>
                        <Paper sx={{ 'margin-top': '2%', overflow: 'hidden' }}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth
                                        }}
                                        >
                                        {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    posts.length > 0? posts.map((post) => {
                                        return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={post.id} onClick={() => navigateToPost(post)}>
                                            <TableCell>
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontSize: 'small' }}
                                                >
                                                    {post.title}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant="body1"
                                                    sx={{ fontSize: 'small' }}
                                                >
                                                    {post.createdAt}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        );
                                    }) : (<TableRow><TableCell colSpan={3} align="center">There is no content</TableCell></TableRow>)
                                }
                                </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={posts.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default BlogPost;