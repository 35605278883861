import React, { useState, useEffect } from 'react';

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    IconButton,
    Collapse,
    Typography
} from "@mui/material";

import {
    updatePost as updatePostMutation,
    deletePost as deletePostMutation
} from "../../../../graphql/customMutations";

import AlertDialog from '../../../Props/AlertDialog';
import UpdateContent from '../../../Props/UpdateContent';

export default function ManagePost({ posts, setPosts, client, tokens }) {
    async function updatePost({ id, title, content }) {
        try {
            await client.graphql({
                query: updatePostMutation,
                variables: { input: { id, title, content } },
                authMode: "userPool",
                authToken: tokens.accessToken
            });
            // Update the local state
            const updatedPosts = posts.map(post => 
                post.id === id ? { ...post, title, content, updatedAt: new Date().toISOString() } : post
            );
            setPosts(updatedPosts); // Update state with the new list of posts
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    }

    async function deletePost({ id }) {
        try {
            const newPosts = posts.filter((post) => post.id !== id);
            setPosts(newPosts);
            await client.graphql({
                query: deletePostMutation,
                variables: { input: { id } },
                authMode: "userPool",
                authToken: tokens.accessToken
            });
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    }
    return (
        <Box sx={{
                width: "100%",
                m: 2
            }}
        >
            <Typography variant="h6">
                Posts
            </Typography>
            <Table size="small" aria-label="purchases">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="center">Title</TableCell>
                        <TableCell align="center">Last Update At</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(posts && posts.length > 0) ? posts.map((post) => (
                        <TableRow key={post.id || post.title}>
                            <TableCell />
                            <TableCell align="center">
                                {post.title}
                            </TableCell>
                            <TableCell align="center">
                                {post.updatedAt}
                            </TableCell>
                            <TableCell align="center">
                                <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: "center"
                                    }}
                                >
                                    <UpdateContent
                                        callback={(title, content) => {
                                            updatePost({
                                                id: post.id,
                                                title,
                                                content
                                            });
                                        }}
                                        post={post}
                                    />
                                    <AlertDialog
                                        callback={() => deletePost(post)}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    )) : (
                        <TableRow>
                            <TableCell colSpan={3} align="center">
                                There is no content
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    )
}