import React, { useState } from "react";
import { Interactions } from '@aws-amplify/interactions';
import { Box, Paper, TextField, Typography, IconButton } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

export default function BotInteraction() {
    const [userInput, setUserInput] = useState("");
    const [chatHistory, setChatHistory] = useState([{ sender: "Bot", message: "Hello, how can I help?" }]);

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    }

    const botInteraction = async (event) => {
        if (event.key && event.key !== 'Enter') return;
        const response = await Interactions.send({
            botName: "HelpDesk",
            message: userInput
        });

        console.log(response);

        // Append user input and bot response to chat history
        setChatHistory(prevHistory => [
            ...prevHistory,
            { sender: "User", message: userInput },
        ]);

        // Append user input and bot response to chat history
        if (response.messages) {
            let botResponses = response.messages.map(item => item.content);
            //iterates over the array's elements directly
            for (let message of botResponses) {
                setChatHistory(prevHistory => [
                    ...prevHistory,
                    { sender: "Bot", message: message },
                ]);
            }
        } else {
            setChatHistory(prevHistory => [
                ...prevHistory,
                { sender: "Bot", message: null }
            ]);
        }

        // Clear the input field
        setUserInput("");
    }

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper elevation={1} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: 2 }}>
                <Box sx={{ flex: 1, overflowY: 'auto', marginBottom: 2, border: 1, borderColor: '#dcdcdc', borderRadius: '3px', px: 1, py: 1 }}>
                    {/* Render chat history */}
                    {chatHistory.map((chat, index) => (
                        <Box 
                            key={index} 
                            sx={{ 
                                marginBottom: 1, 
                                display: 'flex', 
                                justifyContent: chat.sender === "User" ? 'flex-end' : 'flex-start'
                            }}
                        >
                            <Box 
                                sx={{ 
                                    backgroundColor: chat.sender === "User" ? '#b6b6ed' : '#dadada', 
                                    color: 'white',
                                    borderRadius: 2,
                                    padding: 1,
                                    maxWidth: '70%',
                                }}
                            >
                                <Typography 
                                    variant="body1" 
                                    sx={{
                                        fontSize: 'small'
                                    }}
                                >
                                    {chat.sender}: {chat.message}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                        label="Input"
                        variant="outlined"
                        value={userInput}
                        size="small"
                        onChange={handleInputChange}
                        onKeyDown={botInteraction}
                        fullWidth
                    />
                    <IconButton onClick={botInteraction} sx={{ color: 'blue', marginLeft: 2 }}>
                        <SendIcon />
                    </IconButton>
                </Box>
            </Paper>
        </Box>
    )
}