import { Component } from "react";
import {
    Grid,
    Box,
    Typography
} from "@mui/material";
  import { 
    PanelWrapper,
    WidgetContainer,
    Widget
} from "./UserPortalWidgetsElements";

import DashboardIcon from '@mui/icons-material/Dashboard';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import SchoolIcon from '@mui/icons-material/School';

class UserPortalWidgets extends Component {
    render() {
        const username = this.props.username;
        return (
            <PanelWrapper>
                <WidgetContainer>
                    <Grid
                        container
                        rowSpacing={'5vh'}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={3} md={4}>
                            <Widget elevation={6} sx={{ backgroundColor: '#fff', "&:hover": { boxShadow: 2 } }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <DashboardIcon />
                                    <Typography style={{ color: '#1a1966' }}>Dashboard</Typography>
                                </Box>
                            </Widget>
                        </Grid>
                        <Grid item xs={3} md={4}>
                            <Widget elevation={6} sx={{backgroundColor: '#fff', "&:hover": { boxShadow: 2 } }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <SupportAgentIcon />
                                    <Typography style={{ color: '#1a1966' }}>Submit a Support Ticket</Typography>
                                </Box>
                            </Widget>
                        </Grid>
                        <Grid item xs={3} md={4}>
                            <Widget elevation={6} sx={{backgroundColor: '#fff', "&:hover": { boxShadow: 2 } }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <AttachMoneyIcon />
                                    <Typography style={{ color: '#1a1966' }}>Billing and Payments</Typography>
                                </Box>
                            </Widget>
                        </Grid>
                        <Grid item xs={3} md={4}>
                            <Widget elevation={6} sx={{backgroundColor: '#fff', "&:hover": { boxShadow: 2 } }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <FolderOpenIcon />
                                    <Typography style={{ color: '#1a1966' }}>File Sharing and Storage</Typography>
                                </Box>
                            </Widget>
                        </Grid>
                        <Grid item xs={3} md={4}>
                            <Widget elevation={6} sx={{backgroundColor: '#fff', "&:hover": { boxShadow: 2 } }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <EditCalendarIcon />
                                    <Typography style={{ color: '#1a1966' }}>Request Services or Schedule Appointments</Typography>
                                </Box>
                            </Widget>
                        </Grid>
                        <Grid item xs={3} md={4}>
                            <Widget elevation={6} sx={{backgroundColor: '#fff', "&:hover": { boxShadow: 2 } }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <SchoolIcon />
                                    <Typography style={{ color: '#1a1966' }}>Knowledge Base</Typography>
                                </Box>
                            </Widget>
                        </Grid>
                    </Grid>
                </WidgetContainer>
            </PanelWrapper>
        )
    }
}

export default UserPortalWidgets;