import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { Flex } from "@aws-amplify/ui-react";
const SearchBar = () => {
    return (
        <form>
            <Flex style={{ justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                    id="search-bar"
                    variant="standard"
                    placeholder="Search..."
                    size="small"
                    InputProps={{
                        style: { color: 'white' },
                    }}
                    InputLabelProps={{
                        style: { color: 'white' },
                    }}
                    sx={{
                        '& .MuiInput-underline:before': {
                            borderBottomColor: 'white',
                        },
                        '& .MuiInput-underline:hover:before': {
                            borderBottomColor: 'white',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: 'white',
                        }
                    }}
                />
                <IconButton type="submit" aria-label="search">
                    <SearchIcon style={{ fill: "white" }} />
                </IconButton>
            </Flex>
        </form>
    )
}

export default SearchBar;