import React, { useState, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import { fetchUserAttributes } from '@aws-amplify/auth';

const UserMenu = ({ username, signOut, handleClickOpen }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        printUserAttributes();
    }, []);

    const printUserAttributes = async () => {
        try {
            const userAttributes = await fetchUserAttributes();
            setUser(userAttributes);
        } catch (e) {
            console.log(e);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <div style={{ display: 'flex' }}>
            <IconButton onClick={handleClick}>
                <Avatar
                    src={ (user && user.picture !== '') ? user.picture : require('../../../image/computer_science.jpg')}
                    alt="Avatar"
                    style={{ marginLeft: "5px" }}
                />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { handleClickOpen(); handleClose(); }}>
                    <ListItemIcon>
                        <AccountBoxIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <AdminPanelSettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>My account</ListItemText>
                </MenuItem>
                <MenuItem onClick={signOut}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UserMenu;
