// src/views/ExperienceMobileView.js

import React from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import DrawerComponent from "../../components/Drawer";
import employment from "../../data/employmentData";
import { NavBtnLink } from "../../components/Navbar/NavbarElements";

export default function ExperienceMobileView() {
    return (
        <Box>
            <DrawerComponent />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '80%',
                    margin: 'auto',
                    padding: 2,
                }}
            >
                <Typography
                    variant='h4'
                    sx={{
                        marginBlock: 1,
                        marginBottom: 2,
                    }}
                >
                    My Experience
                </Typography>
                <Box
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <NavBtnLink to={'/myCV'}>View my CV</NavBtnLink>
                </Box>
                {employment.map((job, index) => (
                    <Paper
                        key={index}
                        sx={{
                            padding: 2,
                            marginBottom: 2,
                            backgroundColor: 'rgb(255, 255, 255, 0.9)'
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {job.title}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Typography variant="subtitle2" color="textSecondary" sx={{ marginRight: 1 }}>
                                        {job.company}
                                    </Typography>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {job.serviceStart} - {job.serviceEnd}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    {job.summary}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Accomplishments:
                                </Typography>
                                <ul>
                                    {job.accomplishments.map((accomplishment, accIndex) => (
                                        <li key={accIndex}>
                                            <Typography variant="body2">
                                                {accomplishment}
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Technologies:
                                </Typography>
                                <Grid
                                    container
                                    alignItems="center"
                                    spacing={1}
                                >
                                    {job.technologies.map((technology, tecIndex) => (
                                        <Grid item key={tecIndex}>
                                            <Paper sx={{ padding: '2px', borderRadius: '0.5rem', backgroundColor: 'rgb(220, 250, 230, 0.7)' }}>
                                                <Typography color={'#3c9bd6'} fontSize={'x-small'}>{technology}</Typography>
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </Box>
        </Box>
    );
}
