import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    OutlinedInput,
    FormControl,
    Alert
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { fetchUserAttributes, updateUserAttributes } from '@aws-amplify/auth';

const UserProfile = ({ open, handleClickClose }) => {
    const [user, setUser] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [preferredUsername, setPreferredUsername] = useState('');
    const [gender, setGender] = useState('');
    const [birthdate, setBirthdate] = useState(null);
    const url = process.env.REACT_APP_IMAGE_UPLOAD;

    useEffect(() => {
        printUserAttributes();
    }, []);

    const printUserAttributes = async () => {
        try {
            const userAttributes = await fetchUserAttributes();
            setUser(userAttributes);
            setGivenName(userAttributes.given_name || '');
            setFamilyName(userAttributes.family_name || '');
            setMiddleName(userAttributes.middle_name || '');
            setPreferredUsername(userAttributes.preferred_username || '');
            setGender(userAttributes.gender || '');
            setBirthdate(userAttributes.birthdate || '');
        } catch (e) {
            console.log(e);
        }
    };

    const uploadImage = async (file) => {
        try {
            const formData = new FormData();
            formData.append("user", file);
            const response = await fetch(url, {
                method: "POST",
                body: formData
            });
            if (response.ok) {
                const json = await response.json();
                return json.imageUrl;
            } else {
                throw new Error(`Response status: ${response.status}`);
            }

        } catch (error) {
            console.error(error.message);
            return '';
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size > 2 * 1024 * 1024) {
            setFileError('File size exceeds 2 MB. You will not be able to update your profile picture.');
        } else {
            setFileError(null);
            // Handle file upload
        }
    };

    const updateUser = async (event) => {
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        const imageUrl = (form.get("picture").name == '' || fileError !== null) ? '' : await uploadImage(form.get("picture"));
        const data = {
            given_name: (form.get("given_name") == '' && user.given_name !== undefined) ? user.given_name : form.get("given_name"),
            family_name: (form.get("family_name") == '' && user.family_name !== undefined) ? user.family_name : form.get("family_name"),
            middle_name: (form.get("middle_name") == '' && user.middle_name !== undefined) ? user.middle_name : form.get("middle_name"),
            preferred_username: (form.get("preferred_username") == '' && user.preferred_username !== undefined) ? user.preferred_username : form.get("preferred_username"),
            gender: (form.get("gender") == '' && user.gender !== undefined) ? user.gender : form.get("gender"),
            birthdate: (form.get("birthdate") == '' && user.birthdate !== undefined) ? user.birthdate : form.get("birthdate"),
            picture: (imageUrl == '' && user.picture !== undefined) ? user.picture : imageUrl
        };
        try {
            await updateUserAttributes({
                userAttributes: data
            });
            window.location.reload();
        } catch (error) {
            console.error('Error updating user attributes:', error);
        }
        handleClickClose();
    }
    return (
        <Dialog
            open={open}
            onClose={handleClickClose}
            PaperProps={{
                component: 'form',
                onSubmit: updateUser
            }}
        >
            <DialogTitle>{"User Profile"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To personalise your experience, we would like to learn more about your personal information. Please enter your information here.
                </DialogContentText>
                {user ? (
                    <div>
                        <TextField
                            fullWidth
                            margin='dense'
                            name='given_name'
                            label='Given Name'
                            value={givenName}
                            onChange={(e) => setGivenName(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            margin='dense'
                            name='family_name'
                            label='Family Name'
                            value={familyName}
                            onChange={(e) => setFamilyName(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            margin='dense'
                            name='middle_name'
                            label='Middle Name'
                            value={middleName}
                            onChange={(e) => setMiddleName(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            margin='dense'
                            name='preferred_username'
                            label='Preferred Username'
                            value={preferredUsername}
                            onChange={(e) => setPreferredUsername(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            margin='dense'
                            name='gender'
                            label='Preferred Pronoun'
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    name="birthdate"
                                    label='Birth Date'
                                    defaultValue={dayjs(birthdate)}
                                    sx={{width: '100%'}}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        <DialogContentText
                            sx={{ px: 0, py: 1.5 }}
                        >
                            Profile Picture (Max. 2MB)
                        </DialogContentText>
                        <FormControl sx={{ width: '100%' }} variant="outlined">
                            <OutlinedInput
                                margin='dense'
                                name='picture'
                                type='file'
                                onChange={handleFileChange}
                            />
                            {fileError && <Alert severity="error">{fileError}</Alert>}
                        </FormControl>
                    </div>
                ) : <p>Loading...</p>}
                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClickClose} color="primary">Cancel</Button>
                <Button type="submit">Update User</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserProfile;
