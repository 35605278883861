import { Component } from "react";
import { 
    Box,
    Paper,
    Typography
} from "@mui/material";
import UserMenuParent from "../UserManuParent";

class UserPortalTopBar extends Component {
    render() {
        const username = this.props.username;
        const signOut = this.props.signOut;
        return (
            <Box className="content-mobile">
                <Paper elevation={2} sx={{ backgroundColor: '#151452', borderRadius: '10vh', height: '10vh' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <UserMenuParent signOut={signOut} style={{ height: '10vh' }} />
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                                <Typography
                                    variant="h6"
                                    color={'white'}
                                    sx={{
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                    }}
                                >
                                    {username}'s Portal
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        )
    }
}

export default UserPortalTopBar;