import React, { useState, useEffect } from "react";
import {
    useTheme,
    useMediaQuery,
    Box,
    Paper,
    Typography,
    Divider
} from '@mui/material';
import { generateClient } from 'aws-amplify/api';
import { getPost as getPostQuery } from "../../graphql/queries";
import {
    createComment as createCommentMutation,
    createReply as createReplyMutation
} from "../../graphql/mutations";
import DrawerComponent from '../Drawer';
import Navbar from '../Navbar';
import { useParams } from "react-router-dom";
import MyInfo from "../Welcome/MyInfo";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    RedditShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    RedditIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';

const Post = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { id } = useParams();
    const [post, setPost] = useState({});
    const [comments, setComments] = useState([]);
    const [commentContent, setCommentContent] = useState('');
    const [replyContent, setReplyContent] = useState('');
    const [activeReplyId, setActiveReplyId] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state
    const client = generateClient();

    const toggleReply = (commentId) => {
        setActiveReplyId(activeReplyId === commentId ? null : commentId);
    };

    useEffect(() => {
        const fetchData = async () => {
            await getContentfromPost({ id });
            setLoading(false); // Set loading to false after data is fetched
        };
        fetchData();
    }, [id]);

    async function getContentfromPost({ id }) {
        const apiData = await client.graphql({
            query: getPostQuery,
            variables: { id: id },
        });
        const postsFromAPI = apiData.data.getPost;
        setPost(postsFromAPI);
        setComments(postsFromAPI.comments.items);
    }

    async function handlePostComment() {
        if (commentContent.trim() === '') {
            return; // Prevent posting empty comments
        }
        await createComment({ id, content: commentContent });
        // Clear comment content after posting
        setCommentContent('');
        // Refresh comments
        getContentfromPost({ id });
    }

    async function createComment({ id, content }) {
        const data = {
            postCommentsId: id,
            content: content,
        };
        await client.graphql({
            query: createCommentMutation,
            variables: { input: data },
        });
    }

    async function handlePostReply(commentId, content) {
        await createReply({ id: commentId, content });
        // Clear reply content after posting
        setReplyContent('');
        // Refresh comments
        getContentfromPost({ id });
    }

    async function createReply({ id, content }) {
        const data = {
            commentReplysId: id,
            content: content,
        };
        await client.graphql({
            query: createReplyMutation,
            variables: { input: data },
        });
    }

    if (loading) {
        return (
            <Box height="100vh" display="flex" flexDirection="column">
                {isMobile ? (
                    <DrawerComponent />
                ) : (
                    <Navbar />
                )}
            </Box>
        );
    }

    const shareUrl = window.location.href; // The URL to share
    const title = post.title; // The title to share

    return (
        <Box height="100vh" display="flex" flexDirection="column">
            {isMobile ? (
                <Box flexGrow={1} display="flex" flexDirection="column">
                    <Box flexShrink={0}>
                        <Paper
                            square
                            className="post-content is-scrollLocked is-momentumScrollable"
                            sx={{
                                minHeight: '60px',
                                alignContent: 'center',
                                backgroundColor: "rgb(220, 250, 230)"
                            }}
                        >
                            <DrawerComponent />
                        </Paper>
                    </Box>
                    <Box flexGrow={1} display="flex">
                        <Paper
                            square
                            className="is-scrollLocked is-momentumScrollable"
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexGrow: 1
                            }}
                        >
                            <Box
                                sx={{
                                    width: '80%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginX: 'auto',
                                    alignContent: 'center'
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        marginBlock: 4,
                                        marginBottom: 4,
                                    }}
                                >
                                    {post.title}
                                </Typography>
                                <Box dangerouslySetInnerHTML={{ __html: post.content }} />
                                <Divider />
                                <Box sx={{ display: 'flex', marginTop: 2 }}>
                                    <Typography
                                        variant="h4"
                                    >
                                        Share this post
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', marginTop: 2, marginBottom: 3 }}>
                                    <Box sx={{ mx: 1 }}>
                                        <FacebookShareButton url={shareUrl} title={title}>
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                    </Box>
                                    <Box sx={{ mx: 1 }}>
                                        <TwitterShareButton url={shareUrl} title={title}>
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                    </Box>
                                    <Box sx={{ mx: 1 }}>
                                        <LinkedinShareButton url={shareUrl} title={title}>
                                            <LinkedinIcon size={32} round />
                                        </LinkedinShareButton>
                                    </Box>
                                    <Box sx={{ mx: 1 }}>
                                        <RedditShareButton url={shareUrl} title={title}>
                                            <RedditIcon size={32} round />
                                        </RedditShareButton>
                                    </Box>
                                    <Box sx={{ mx: 1 }}>
                                        <WhatsappShareButton url={shareUrl} title={title}>
                                            <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                    </Box>
                                    <Box sx={{ mx: 1 }}>
                                        <EmailShareButton url={shareUrl} title={title}>
                                            <EmailIcon size={32} round />
                                        </EmailShareButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            ) : (
                <Box flexGrow={1} display="flex" flexDirection="column">
                    <Navbar />
                    <Box flexGrow={1} display="flex">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%'
                            }}
                        >
                            <MyInfo />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1
                                }}
                            >
                                <Paper square className="post-content is-scrollLocked is-momentumScrollable" sx={{ flexGrow: 1 }}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            marginBlock: 2,
                                            marginBottom: 4,
                                        }}
                                    >
                                        {post.title}
                                    </Typography>
                                    <Box dangerouslySetInnerHTML={{ __html: post.content }} />
                                    <Divider />
                                    <Box sx={{ display: 'flex', marginTop: 2 }}>
                                        <Typography
                                            variant="h4"
                                        >
                                            Share this post
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginTop: 2, marginBottom: 3 }}>
                                        <Box sx={{ mx: 1 }}>
                                            <FacebookShareButton url={shareUrl} title={title}>
                                                <FacebookIcon size={32} round />
                                            </FacebookShareButton>
                                        </Box>
                                        <Box sx={{ mx: 1 }}>
                                            <TwitterShareButton url={shareUrl} title={title}>
                                                <TwitterIcon size={32} round />
                                            </TwitterShareButton>
                                        </Box>
                                        <Box sx={{ mx: 1 }}>
                                            <LinkedinShareButton url={shareUrl} title={title}>
                                                <LinkedinIcon size={32} round />
                                            </LinkedinShareButton>
                                        </Box>
                                        <Box sx={{ mx: 1 }}>
                                            <RedditShareButton url={shareUrl} title={title}>
                                                <RedditIcon size={32} round />
                                            </RedditShareButton>
                                        </Box>
                                        <Box sx={{ mx: 1 }}>
                                            <WhatsappShareButton url={shareUrl} title={title}>
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>
                                        </Box>
                                        <Box sx={{ mx: 1 }}>
                                            <EmailShareButton url={shareUrl} title={title}>
                                                <EmailIcon size={32} round />
                                            </EmailShareButton>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default Post;
