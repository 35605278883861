import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import FunctionList from "../FunctionList";
import BasicTabs from "../../BasicTabs";
import BottomToolBar from "../BottomToolBar";
import BotInteraction from "../../Chatbot/BotInteraction";

export default function CenterPanel({user}) {
    const [activeTab, setActiveTab] = useState("functionList");
    return (
        <Box className="content-mobile">
            <Paper sx={{ backgroundColor: 'white', borderRadius: '1vh', height: '70vh', overflow: 'scroll' }}>
                {activeTab === "functionList" && <FunctionList />}
                {activeTab === "basicTabs" && <BasicTabs user={user} />}
                {activeTab === "chatbot" && <BotInteraction />}
            </Paper>
            <BottomToolBar setActiveTab={setActiveTab} />
        </Box>
    );
}
