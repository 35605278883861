import React from 'react';
import ExperienceDesktopView from '../layouts/desktop/experience';
import ExperienceMobileView from '../layouts/mobile/experience';
import {
	useTheme,
	useMediaQuery,
	Box,
} from "@mui/material";

const Experience = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<Box>
			{isMobile ? (
				<ExperienceMobileView />
			) : (
				<ExperienceDesktopView />
			)}
		</Box>
	);
};

export default Experience;
