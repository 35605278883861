import React, { useState, useEffect } from "react";
import { generateClient } from 'aws-amplify/api';
import {
  createProject as createProjectMutation,
} from "../graphql/mutations";
import { Box, TextField, Typography, Button } from "@mui/material";

export default function NewProject({ tokens }) {
  const client = generateClient();

  async function createProject(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      name: form.get("name"),
      description: form.get("description"),
      completion: 0.0,
      status: true,
    };
    await client.graphql({
      query: createProjectMutation,
      variables: { input: data },
      authMode: "userPool",
      authToken: tokens.accessToken
    });
    event.target.reset();
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Create New Project</Typography>
      <Box
        component="form"
        sx={{
          mt: 4,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          border: '1px solid #ccc',
          borderRadius: '8px',
          boxShadow: 2,
        }}
        onSubmit={createProject}
      >
        <TextField
          name="name"
          placeholder="Project Name"
          label="Project Name"
          required
          fullWidth
        />
        <TextField
          name="description"
          placeholder="Project Description"
          label="Project Description"
          multiline
          required
          fullWidth
          rows={4}
        />
        <Button type="submit" variant="contained" color="primary">
          Create Project
        </Button>
      </Box>
    </Box>
  );
}
