import { Avatar, Box, Paper, Typography, IconButton } from "@mui/material";
import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { NavBtnLink } from "../Navbar/NavbarElements";

export default function MyInfo() {
    return (
        <Box
            sx={{
                width: '25%',
                minWidth: '25%',
                maxWidth: '320px',
                minHeight: '90vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Paper
                elevation={1}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    backgroundColor: 'rgba(255, 255, 255, 0.2)'
                }}
            >
                <Box
                    sx={{
                        position: 'sticky',
                        top: '10vh',
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 2,
                            py: 1.5,
                        }}
                    >
                        <Avatar
                            src={require('../../image/ProfilePic.jpg')}
                            sx={{
                                height: '160px',
                                width: '160px'
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 1,
                            py: 1.5,
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{ marginBottom: 2 }}
                        >
                            Who I am
                        </Typography>
                        <Typography
                            variant="body1"
                            textAlign={'center'}
                        >
                            Hi, I am Arthur, a full stack software engineer specialising in <b>web development</b> and <b>chatbot development</b>.
                        </Typography>
                        <Typography
                            variant="body1"
                            textAlign={'center'}
                        >
                            I am most experienced in Python, ReactJS, NodeJS, and AWS.
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                gap: 1,
                                py: 1.5,
                            }}
                        >
                            <IconButton href="https://www.linkedin.com/in/tsz-chung-chan-59964616b" target="_blank">
                                <LinkedInIcon className="muiIcon"/>
                            </IconButton>
                            <IconButton href="https://github.com/tszchung019" target="_blank">
                                <GitHubIcon className="muiIcon"/>
                            </IconButton>
                        </Box>
                        <Box>
                            <NavBtnLink to={'/myCV'}>View my CV</NavBtnLink>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
