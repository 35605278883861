import React from "react";
import "../css/styles.css";
import BlogsDesktopView from '../layouts/desktop/blogs.js';
import EventsMobileView from '../layouts/mobile/blogs.js';
import {
	useTheme,
	useMediaQuery,
  Box
} from "@mui/material";

const Blogs = () => {
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      {isMobile ? (
        <EventsMobileView />
      ) : (
        <BlogsDesktopView />
      )}
    </Box>
  );
};

export default Blogs;
