import { Flex } from "@aws-amplify/ui-react";
import { Button, IconButton } from "@mui/material";

import styled from 'styled-components';
import bannerImage from '../../../image/Navy_Blue_Banner.png';

export const BannerWrapper = styled.div`
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${bannerImage});
  background-size: cover; /* Ensures the image covers the whole area */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
`;

export const TopPanel = styled(Flex)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MiddlePanel = styled(Flex)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomPanel = styled(Flex)`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchBarWrapper = styled.div`
  margin-right: 10px;
  padding: 10px 20px;
`;

export const UserInterfaceElements = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  padding: 0 20px;
`;

export const HomeIconContainer = styled(IconButton)`
  margin-right: 10px;
`;

export const SignOutButton = styled(Button)`
  margin-left: 10px;
`;