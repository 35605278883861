import { Box } from '@mui/material';
import React from 'react';
// Desktop components
import UserPortalBanner from './UserPortalBanner';
import UserPortalWidgets from './UserPortalWidgets';
// Mobile components
import UserPortalTopBar from './UserPortalTopBar';
import {
    useTheme,
    useMediaQuery
} from "@mui/material";
import BotWidget from '../Chatbot/BotWidget';
import CenterPanel from './CenterPanel';

const UserPortal = ({ username, signOut }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        isMobile ? (
            <Box className="mobile">
                <UserPortalTopBar username={username} signOut={signOut} />
                <CenterPanel user={username} />
            </Box>
        ) : (
            <Box className="desktop">
                <UserPortalBanner username={username} signOut={signOut} />
                <UserPortalWidgets />
                <BotWidget />
            </Box>
        )
    )
}

export default UserPortal;