import React, { useState } from 'react';
import DrawerComponent from '../../components/Drawer';
import { Box, Divider, Grid, Paper, Typography, MenuItem, FormControl, TextField } from '@mui/material';
import skills from '../../data/skillData';

export default function SkillsDesktopView() {
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const handleGroupChange = (event) => {
        setSelectedGroup(event.target.value);
        setSelectedCategory(''); // Reset category when group changes
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredSkills = skills
        .filter(skillGroup => !selectedGroup || skillGroup.group === selectedGroup)
        .map(skillGroup => ({
            ...skillGroup,
            categories: skillGroup.categories.filter(category => !selectedCategory || category.catName === selectedCategory)
        }))
        .map(skillGroup => ({
            ...skillGroup,
            categories: skillGroup.categories.map(category => ({
                ...category,
                items: category.items.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()))
            })).filter(category => category.items.length > 0)
        }))
        .filter(skillGroup => skillGroup.categories.length > 0);

    return (
        <Box>
            <DrawerComponent />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        alignItems: 'center'
                    }}
                >
                    <Paper elevation={1} sx={{ width: '80vw', margin: '16px 0', padding: '10px', backgroundColor: "rgb(255, 255, 255, 0.9)" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label='Group'
                                        size='small'
                                        value={selectedGroup}
                                        onChange={handleGroupChange}
                                    >
                                        <MenuItem value=''><em>None</em></MenuItem>
                                        {skills.map((skillGroup, index) => (
                                            <MenuItem key={index} value={skillGroup.group}>{skillGroup.group}</MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label='Category'
                                        size='small'
                                        value={selectedCategory}
                                        onChange={handleCategoryChange}
                                        disabled={!selectedGroup}
                                    >
                                        <MenuItem value=''><em>None</em></MenuItem>
                                        {skills.find(skillGroup => skillGroup.group === selectedGroup)?.categories.map((category, catIndex) => (
                                            <MenuItem key={catIndex} value={category.catName}>{category.catName}</MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label="Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        {filteredSkills.map((skillGroup, index) => (
                            <Grid item xs="auto" key={index}>
                                <Paper
                                  elevation={1}
                                    sx={{
                                        width: '80vw',
                                        margin: '16px 0',
                                        padding: '16px',
                                        backgroundColor: "rgb(255, 255, 255, 0.9)"
                                    }}
                                >
                                    <Typography variant="h6" component="h6">
                                        {skillGroup.group}
                                    </Typography>
                                    <Divider />
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="space-evenly"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      {skillGroup.categories.map((category, catIndex) => (
                                        <Grid item key={catIndex}>
                                          <Box sx={{ marginTop: '8px', padding: '8px' }}>
                                              <Typography variant="subtitle1" component="h3" textAlign={'center'}>
                                                  {category.catName}
                                              </Typography>
                                              <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={1}
                                              >
                                                  {category.items.map((item, itemIndex) => (
                                                      <Grid item key={itemIndex}>
                                                          <Paper sx={{ padding: '4px', borderRadius: '1rem', backgroundColor: 'rgb(220, 250, 230)' }}>
                                                              <Typography color={'#3c9bd6'} fontSize={'x-small'}>{item}</Typography>
                                                          </Paper>
                                                      </Grid>
                                                  ))}
                                              </Grid>
                                          </Box>
                                        </Grid>
                                      ))}
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
