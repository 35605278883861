import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession } from "aws-amplify/auth";
import {
  listProjects as listProjectsQuery,
} from "../graphql/queries";

const columns = [
  { id: 'name', label: 'Project Name', minWidth: 170 },
  {
    id: 'createdAt',
    label: 'Start Date',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-GB', { timeZone: 'UTC' }),
  },
  {
    id: 'completion',
    label: 'Completion\u00a0(%)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
];

export default function CurrentProjects({user}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [projects, setProjects] = useState([]);
  const [tokens, setTokens] = useState(null);
  const client = generateClient();

  useEffect(() => {
    fetchToken();
  }, []);

  useEffect(() => {
    if (tokens) {
      fetchProjects();
    }
  }, [tokens]);

  async function fetchProjects() {

    // const apiData = await client.graphql({
    //   query: listUsersQuery,
    //   variables: {filter: {name: {eq: username}}},
    // });
    // const usersFromAPI = apiData.data.listUsers.items[0].projects.items;

    const apiData = await client.graphql({
      query: listProjectsQuery,
      authMode: "userPool",
      authToken: tokens.accessToken
    });
    const projectsFromAPI = apiData.data.listProjects.items;
    
    setProjects(projectsFromAPI);
  }

  const fetchToken = async () => {
    const tokens = await fetchAuthSession();
    setTokens(tokens);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {projects
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((project) => {
                return (
                  <TableRow hover role="checkbox">
                    {columns.map((column) => {
                      const value = project[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && (typeof value === 'number' || typeof value === 'boolean')
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={projects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}