import React from 'react';
import '../css/styles.css';
import {
	useTheme,
	useMediaQuery,
	Box
} from "@mui/material";
import HomeMobileView from '../layouts/mobile/home';
import HomeDesktopView from '../layouts/desktop/home';

const Home = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Box>
			{isMobile ? (
				<HomeMobileView />
			) : (
				<HomeDesktopView />
			)}
		</Box>
	);
};

export default Home;
