import React, { useState, useEffect } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    IconButton,
    Collapse,
    Typography
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { getBlog as getBlogQuery } from "../../../../graphql/customQueries";
import {
    deleteBlog as deleteBlogMutation,
    createPost as createPostMutation
} from "../../../../graphql/customMutations";
import { listBlogs as listBlogsQuery } from "../../../../graphql/queries";
// import { createPost as createPostMutation } from "../../../../graphql/mutations";

import { generateClient } from 'aws-amplify/api';
import AlertDialog from '../../../Props/AlertDialog';
import CreateContent from '../../../Props/CreateContent';
import ManagePost from './ManagePost';
import UpdateBlog from './UpdateBlog';

export default function ManageBlog({ tokens }) {
    const [blogs, setBlogs] = useState([]);
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [expandedRowId, setExpandedRowId] = useState(null); // To track the expanded row
    const client = generateClient();

    useEffect(() => {
        fetchBlogs();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    async function fetchBlogs() {
        const apiData = await client.graphql({
            query: listBlogsQuery
        });
        const blogsFromAPI = apiData.data.listBlogs.items;
        setBlogs(blogsFromAPI);
    }

    async function getPostsfromBlog(id) {
        try {
            const apiData = await client.graphql({
                query: getBlogQuery,
                variables: { id: id },
            });
            const postsFromAPI = apiData.data.getBlog.posts.items;
            setPosts(postsFromAPI);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    }

    async function deleteBlog({ id }) {
        try {
            await client.graphql({
                query: deleteBlogMutation,
                variables: { input: { id } },
                authMode: "userPool",
                authToken: tokens.accessToken
            });
            const newBlogs = blogs.filter((blog) => blog.id !== id);
            setBlogs(newBlogs);
        } catch (error) {
            console.error('Error deleting blog:', error);
            setBlogs(blogs);
        }
    }

    async function createPost({ id, title, content }) {
        try {
            const data = { blogPostsId: id, title, content };
            await client.graphql({
                query: createPostMutation,
                variables: { input: data },
                authMode: "userPool",
                authToken: tokens.accessToken
            });
        } catch (error) {
            console.error('Error creating post:', error);
        }
      }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "90%"
            }}
        >
            <TableContainer>
                <Typography variant="h6">
                    Blogs
                </Typography>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Last Updated At</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {blogs.map((blog) => {
                            const isRowExpanded = expandedRowId === blog.id; // Determine if the current row is expanded
                            return (
                                <React.Fragment key={blog.id}>
                                    <TableRow>
                                        <TableCell>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => {
                                                    setPosts(null);
                                                    getPostsfromBlog(blog.id);
                                                    setExpandedRowId(isRowExpanded ? null : blog.id); // Toggle expansion
                                                }}
                                            >
                                                {isRowExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                            {blog.name}
                                        </TableCell>
                                        <TableCell align="center">
                                            {blog.updatedAt}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                            <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: "center"
                                                }}
                                            >
                                                {/* Create a post */}
                                                <CreateContent
                                                    callback={(title, content) => {
                                                        createPost({
                                                            id: blog.id,
                                                            title,
                                                            content
                                                        });
                                                    }}
                                                />
                                                {/* Update a blog */}
                                                <UpdateBlog blog={blog} client={client} tokens={tokens} fetchBlogs={fetchBlogs} />
                                                {/* Delete a blog */}
                                                <AlertDialog
                                                    callback={() => deleteBlog(blog)}
                                                />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                            <Collapse in={isRowExpanded} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 1 }}>
                                                    {/* Render more details about the blog here */}
                                                    <ManagePost
                                                        posts={posts}
                                                        setPosts={setPosts}
                                                        client={client}
                                                        tokens={tokens}
                                                    />
                                                    <Typography variant="body2">{blog.description}</Typography>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={blogs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}
