import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import EventIcon from '@mui/icons-material/Event';
import FolderIcon from '@mui/icons-material/Folder';
import WorkIcon from '@mui/icons-material/Work';
import ArticleIcon from '@mui/icons-material/Article';
import PeopleIcon from '@mui/icons-material/People';
import { Link } from "react-router-dom";
import "../css/styles.css"

function DrawerComponent() {
const [openDrawer, setOpenDrawer] = useState(false);
return (
    <>
    <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
            sx: {
              backgroundColor: "rgb(220, 250, 230)",
              width: "250px"
            },
          }}
    >
        <List>
        <ListItem onClick={() => setOpenDrawer(false)} component={Link} to="/">
            <HomeIcon className="navBarIcon"/>
            <ListItemText>
            <text className="navBarItem">Home</text>
            </ListItemText>
        </ListItem>
        <ListItem onClick={() => setOpenDrawer(false)} component={Link} to="/about">
            <InfoIcon className="navBarIcon"/>
            <ListItemText>
            <text className="navBarItem">About</text>
            </ListItemText>
        </ListItem>
        <ListItem onClick={() => setOpenDrawer(false)} component={Link} to="/skills">
            <EventIcon className="navBarIcon"/>
            <ListItemText>
            <text className="navBarItem">Skills</text>
            </ListItemText>
        </ListItem>
        <ListItem onClick={() => setOpenDrawer(false)} component={Link} to="/projects">
            <FolderIcon className="navBarIcon"/>
            <ListItemText>
            <text className="navBarItem">Projects</text>
            </ListItemText>
        </ListItem>
        <ListItem onClick={() => setOpenDrawer(false)} component={Link} to="/experience">
            <WorkIcon className="navBarIcon"/>
            <ListItemText>
            <text className="navBarItem">Experience</text>
            </ListItemText>
        </ListItem>
        <ListItem onClick={() => setOpenDrawer(false)} component={Link} to="/blogs">
            <ArticleIcon className="navBarIcon"/>
            <ListItemText>
            <text className="navBarItem">Blogs</text>
            </ListItemText>
        </ListItem>
        <ListItem id="signupButton" onClick={() => setOpenDrawer(false)} component={Link} to="/signup">
            <PeopleIcon className="navBarIcon"/>
            <ListItemButton>
            <text className="navBarButton">Subscribe/Login</text>
            </ListItemButton>
        </ListItem>
        </List>
    </Drawer>
    <IconButton id="menuIcon" onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
    </IconButton>
    </>
);
}
export default DrawerComponent;