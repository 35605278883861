import {
    Paper
} from "@mui/material";

import styled from 'styled-components';

export const PanelWrapper = styled.div`
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d1d1e0;
`;

export const WidgetContainer = styled.div`
  height: 60vh;
  width: 90vw;
`;

export const Widget = styled(Paper)`
    height: 25vh;
    max-width: 25vw;
    padding: 10px 20px;
    border-radius: 8px;
`;